<template>
  <div>

    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>入库单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.we_code" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.we_status" placeholder="请选择是否作废" clearable  @change="selectstatusList">
            <el-option
                v-for="item in statusList"
                :key="item.we_status"
                :label="item.we_statusname"
                :value="item.we_status">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.we_agent" placeholder="请选择经办人" @change="querInfowecreatorid"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.we_creator_id" placeholder="请选择添加人" @change="querInfowecreatorid"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.s_id" placeholder="请选择供应商" @change="querInfocid"
                     clearable class="p_purchase_id" filterable>
            <el-option
                v-for="item in supplierList"
                :key="item.s_id"
                :label="item.s_company_name"
                :value="item.s_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-cascader
              :options="TreeList" v-model="querInfo.warehouselist" placeholder="请选择仓库"
              :props="Treeprops" @change="getcheckList"
              clearable></el-cascader>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-date-picker v-model="querInfo.we_date" class="p_purchase_id" @change="querInfowodate"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.we_type" placeholder="请选择入库类型" @change="querInfocid"
                     clearable class="p_purchase_id" filterable>
            <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-button type="primary" @click="addpurchaseDialog" icon="el-icon-document-add" v-if="is_add">添加</el-button>
          <el-button type="primary" @click="DownloadWarehouseenter" icon="el-icon-download" v-if="is_export">导出</el-button>
        </el-col>
      </el-row>
      <el-table :data="purchaselist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="订单号" prop="we_code" show-overflow-tooltip ></el-table-column>
        <el-table-column label="入库时间" prop="we_date" show-overflow-tooltip></el-table-column>
        <el-table-column label="经办人" prop="we_agent_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="所属仓库(分区)" prop="w_name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>{{scope.row.w_name}}({{(scope.row.wp_name)}})</div>
          </template>
        </el-table-column>
        <el-table-column label="供应商" prop="s_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="添加人" prop="add_uname"></el-table-column>
        <el-table-column label="添加时间" prop="add_date" show-overflow-tooltip></el-table-column>
        <el-table-column label="入库状态" prop="we_type_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="是否作废">
          <template slot-scope="scope">
            <i class="el-icon-success" v-if="scope.row.we_status==1" style="color: lightgreen"></i>
            <i class="el-icon-error" v-else style="color: red"></i>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="详情" placement="top" :enterable="true">
              <el-button type="primary" size="mini" icon="el-icon-tickets"
                         @click="PurchaseWarehousingLi(`${scope.row.we_id}`)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="作废" placement="top" :enterable="true" v-if="scope.row.we_status==1&&is_nullify">
              <el-button type="danger" size="mini"  icon="el-icon-document-delete"
                       @click="PurchaseWarehoustovoid(`${scope.row.we_id}`)"></el-button>
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return{
      querInfo: {
        page: 1,
        limit: 10,
        we_code:''
      },
      typeList:[
        {id:1,name:'采购订单入库'},
        {id:2,name:'其他入库'},
        {id:3,name:'销售退货入库'},
      ],
      //页面权限
      WarehouseenterJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_nullify:false,
      is_export:false,
      is_printing:false,
      //表格加载
      loading:true,
      //采购订单数据列表
      purchaselist:[],
      total:0,
      statusList:[{we_status:0,we_statusname:'全部'},{we_status:1,we_statusname:'正常'},{we_status:2,we_statusname:'作废'}],
      TreeList:[],
      supplierList:[],
      userlist:[],
      Treeprops:{
        expandTrigger: 'hover',
        value: 'w_id',
        label: 'w_name',
        children: 'children'},

    }
  },
  created() {
    this.getPurchaselist()
    this.getWarehouseenterJurisdiction()
    this.showSetTreeList()
    this.getsupplierList()
    this.getuserList()
  },
  activated(){
    this.getPurchaselist()
    this.showSetTreeList()
    this.getsupplierList()
    this.getuserList()
  },
  methods: {
    //获取权限接口
    async getWarehouseenterJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.WarehouseenterJurisdiction=res.data
      this.WarehouseenterJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='nullify'){
          this.is_nullify=true
        }else if (item.m_path=='export'){
          this.is_export=true
        }else if(item.m_path=='printing'){
          this.is_printing=true
        }
      })
    },
    async getPurchaselist() {
      this.loading=true
      const {data: res} = await this.$http.get('warehouseenter/getList',
          {params: this.querInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.purchaselist = res.data.data
      this.total = res.data.total
      this.loading=false
    },
    //获取仓库数据
    async showSetTreeList() {
      //获取所有权限的数据
      const {data: res} = await this.$http.get('warehouse/getTreeList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.TreeList = res.data
    },
    //获取供应商列表
    async getsupplierList() {
      const {data: res} = await this.$http.get('supplier/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.supplierList = res.data
    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getPurchaselist()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getPurchaselist()
    },
    //经办人 查询
    querInfowoagent(){
      this.querInfo.page=1
      this.getPurchaselist()
    },
    //添加人 查询
    querInfowecreatorid(){
      this.querInfo.page=1
      this.getPurchaselist()
    },
    //供应商 查询
    querInfocid(){
      this.querInfo.page=1
      this.getPurchaselist()
    },
    //选择时间 查询
    querInfowodate(){
      this.querInfo.page=1
      this.getPurchaselist()
    },
    //选择仓库 查询
    getcheckList(){
      this.querInfo.page=1
      this.querInfo.w_id=this.querInfo.warehouselist[0]
      this.querInfo.wp_id=this.querInfo.warehouselist[1]
      this.getPurchaselist()
    },
    inputchange(){
      this.querInfo.page=1
      this.getPurchaselist()
    },
    addpurchaseDialog(){
      this.$router.push('addwarehouseenter')
    },
    PurchaseWarehousingLi(id){
      this.$router.push({path: "/warehouseenterDetails", query: {editid: id,is_printing:this.is_printing}})
    },
    //选择是否作废
    selectstatusList(){
      this.querInfo.page=1
      this.getPurchaselist()
    },
    //作废接口
    async PurchaseWarehoustovoid(id){
      const confirmResult = await this.$confirm(
          '此操作将作废该条记录, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消作废')
      }
      const {data:res} = await this.$http.post(`warehouseenter/nullify/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getPurchaselist()
    },
    async DownloadWarehouseenter(){
      return new Promise((resolve, reject) => {
        // console.log(resolve)
        // console.log(reject)
        const {data:res} = this.$http.get('warehouseenter/export', {
          responseType: "blob" // 1.首先设置responseType对象格式为 blob:
        })
            .then(
                res => {
                  //resolve(res)
                  let blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel"
                  }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                  let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
                  // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
                  let a = document.createElement("a");
                  var date = new Date();
                  a.href = url;
                  a.download = "入库单"+date.getFullYear()+"-"+ (date.getMonth()+1) +"-"+date.getDate() +".xlsx";
                  a.click();
                  // 5.释放这个临时的对象url
                  window.URL.revokeObjectURL(url);
                },
                err => {
                  resolve(err.response);
                }
            )
            .catch(error => {
              reject(error);
            });
      });
    }
  }
}
</script>
<style>
.p_purchase_id{
  width: 100%;
}

.el-date-editor.el-input{
  width: 100%;

}
</style>


